// Set file variable
$filename: "scss/custom/_custom-alt.scss";

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

body {
  background: $shade url("/fs-shared/dist/img/pattern.png") repeat center top;
}

/*==============================================================================
  @Nav - #{$filename}
==============================================================================*/

.nav {
  border-bottom: 1px solid rgba($black, 0.1);
}
.nav-menu > li > a {
  color: $color;
  
  &:visited {
    color: $color;
  }
  &:hover,
  &:focus {
    color: $color;
    color: var(--accent-hover, $accent-hover);
  }
}

.nav-menu ul li.current_page_item a {
    color: $color;
    color: var(--accent, $accent);
}

/*==============================================================================
  @Logo - #{$filename}
==============================================================================*/

.logo {
  color: $color;

  &:visited {
    color: $color;
  }
  &:hover,
  &:focus {
    color: $color;
  }
}
.logo .vs-logo-sec {
  background-image: url("/fs-shared/dist/img/vs-logo-sec-dark.svg");
}

/**
 * Logo Parts
 */
.logo .member {
  color: $color-light;
}
.logo .sep {
  background: rgba($black, 0.1);
}

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  background: $bg-nav;
  // border-bottom: 1px solid rgba($black, 0.1);

  .button {
    color: $accent;
    color: var(--accent, $accent);
    border-color: var(--accent, $accent);

    &:visited {
      color: $color;
    }
    &:hover,
    &:focus {
      color: $accent;
      color: var(--accent, $accent);
      border-color: var(--accent, $accent);
    }

    .button.text {
      color: $accent;
      color: var(--accent, $accent);
    }
  }
}

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button.primary {
    background: $accent;
    background: var(--accent, $accent);

    &:hover,
    &:focus {
      background: $accent-hover;
      background: var(--accent-hover, $accent-hover);
    }
  }

/*==============================================================================
  @Widget - #{$filename}
==============================================================================*/

.widget {
  background: $shade url("/fs-shared/dist/img/pattern.png") repeat center top;

  .button {
    @extend .button.primary;
  }
}

.widget-locations {
  background: none;
}

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

.featured-action {
  background: $shade url("/fs-shared/dist/img/pattern.png") repeat center top;
}

.featured-action a {
  color: $color-light;

  h2 {
    color: $color;
  }
  .icon {
    color: $accent;
    color: var(--accent, $accent);
  }

  &:visited {
    color: $color-light;
  }
  &:hover,
  &:focus {
    color: $color-light;
    background: rgba($white, 0.5);
  }
}
